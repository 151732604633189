<template>
  <div>
    <v-dialog v-model="showALDialog" persistent scrollable width="800">
      <v-card style="border-radius: 16px">
        <v-card-title style="font-size: 25px; height: 80px">
          <v-icon style="color: #363636" class="mr-1"
            >mdi-plus-box-outline</v-icon
          >
          {{ $t("fromlibrary") }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closeALDialog()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text style="height: 800px">
          <v-row class="mt-2">
            <v-col md="6" lg="6" xl="6" sm="12" class="pb-1">
              <v-text-field
                outlined
                dense
                hide-details
                style="border-radius: 8px; border-color: #c0c0c0"
                clearable
                prepend-inner-icon="mdi-magnify"
                placeholder="search..."
                v-model="search"
                class="mb-1"
              >
              </v-text-field>
              <span class="pl-1" style="color: #ff6060; font-size: 18px"
                > {{ $t("selectarticle") }}</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group name="myGroup">
                <v-data-table
                  :headers="articleheaders"
                  hide-default-header
                  :items="$store.state.assignment.articleList"
                  :search="search"
                  hide-default-footer
                  class="article-table"
                  v-model="selected"
                  item-key="id"
                  disable-pagination
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>
                        <v-radio
                          @change="selectItem(item)"
                          :value="item.contentID"
                          :input-value="selectedItem === item"
                          name="selectedItem"
                          color="#67AB30"
                        ></v-radio>
                      </td>

                      <td>
                        <v-img :src="item.orgImage" class="lessonimg"></v-img>
                      </td>

                      <td>{{ item.contentName }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row>
            <v-col class="pt-4 pb-4" style="text-align: right">
              <v-btn
                class="text-capitalize"
                style="
                  font-size: 16px;
                  color: #424242;
                  border: 1px solid #c0c0c0;
                  border-radius: 8px;
                "
                width="100"
                text
                @click="showALDialog = false"
                >{{ $t("cancel") }}</v-btn
              >
              <v-btn
                class="text-capitalize ml-2"
                width="100"
                style="
                  font-size: 16px;
                  background: #a6cc39 0% 0% no-repeat padding-box;
                  border-radius: 8px;
                  color: #ffffff;
                "
                @click="save_article()"
                >{{ $t("update") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    // articleItems: {
    //   type: Array,
    // },
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selected: [],
      search: null,
      selectedItem: [],
    };
  },

  computed: {
    showALDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    articleheaders() {
      return [
        {
          text: "",
          align: "start",
          value: "orgImage",
          width: "250px",
        },
        {
          text: this.$t("description"),
          align: "left",
          value: "contentName",
        },
      ];
    },
  },

  mounted() {},

  methods: {
    closeALDialog() {
      this.showALDialog = false;
      this.selectedItem = [];
    },
    selectItem(contentID) {
      this.selectedItem = contentID;
    },
    async save_article() {
      let self = this;
      const data = {
        id: self.selectedItem.contentID,
      };
      await self.$axios
        .post(
          `${self.web_urlV6}AssignmentArticle/AddAssignmentArticleFromLibrary`,
          data
        )
        .then(function (response) {
          if (response.data.status == 0) {
            let result = response.data.data ? response.data.data : [];
            self.$store.commit("saveSelectedArticle", result);
            self.$emit("close");
          }
        })
        .catch(function (err) {
          throw err;
        });
    },
  },
};
</script>

<style scoped>
.lessonimg {
  width: 192px !important;
  height: 100px !important;
  border-radius: 12px;
}
.article-table.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 16px 10px !important;
}
::v-deep .v-dialog {
  border-radius: 16px;
  overflow-y: hidden !important;
}
::v-deep .v-application .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
  border-color: #a6cc39 !important;
}
</style>
