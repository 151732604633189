<template>
  <div>
    <v-dialog v-model="showArticleDialog" persistent width="1220" scrollable>
      <v-card style="border-radius: 16px">
        <v-card-title
          style="font-size: 20px; color: #363636; border: 1px solid #c0c0c0"
          class="pb-4"
        >
          <div>
            <v-icon class="mr-1" style="padding-bottom: 3px"
              >mdi-plus-box-outline</v-icon
            >
            {{ $t("editarticle") }}
          </div>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="cancelEditArticle()"
            color="#424242"
            style="border-radius: 8px !important"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 600px">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row class="mt-5">
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row>
                  <v-col cols="12">
                    <label for="title" class="font-style">
                      {{ $t("articletitle") }}
                      <span style="color: red">*</span>
                    </label>
                    <v-text-field
                      :rules="titleRules"
                      v-model="editSelectedArticle.title"
                      outlined
                      dense
                      hide-details="auto"
                      id="title"
                      class="rounded-lg"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label for="link" class="font-style">{{
                      $t("link")
                    }}</label>
                    <v-text-field
                      outlined
                      dense
                      hide-details="auto"
                      id="link"
                      @input="checkLinkType"
                      v-model="editSelectedArticle.link"
                      class="rounded-lg"
                    ></v-text-field>
                    <span v-if="notLink" style="color: red; font-size: 13px">{{
                      $t("invalidUrlLink")
                    }}</span>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="mt-2">
                      <v-col
                        cols="12"
                        sm="4"
                        md="6"
                        lg="4"
                        class="pr-0"
                        style="padding-bottom: 0"
                      >
                        <v-row class="pl-4">
                          <input
                            type="file"
                            accept="application/pdf"
                            class="d-none"
                            ref="pdffile"
                            @change="getpdfFile($event)"
                          />
                          <v-btn
                            color="#A6CC39"
                            style="
                              font-size: 16px;
                              float: left;
                              border-radius: 8px;
                              height: 40px;
                            "
                            class="text-capitalize white--text btn_hover_effect"
                            :disabled="editSelectedArticle.PDFButtonDisable"
                            @click="
                              ($refs.pdffile.value = null),
                                $refs.pdffile.click()
                            "
                          >
                            <v-icon class="mr-1"
                              >mdi-folder-search-outline</v-icon
                            >
                            {{ $t("Browse File") }}
                          </v-btn>

                          <span
                            class="mt-1"
                            style="color: #777777; font-size: 12px"
                            >{{ $t("RecommendFormatPDF") }}</span
                          >
                        </v-row>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                        lg="6"
                        class="pl-0"
                        style="padding-bottom: 0"
                      >
                        <label
                          for="browsefiles"
                          v-if="editSelectedArticle.pdfName != ''"
                          class="font-style"
                          >{{ editSelectedArticle.pdfName }}</label
                        >
                        <v-icon
                          color="#FF6060"
                          @click="DeletePDFfile"
                          v-if="editSelectedArticle.PDFButtonDisable"
                          style="
                            cursor: pointer;
                            border: 1px;
                            margin-left: 5px;
                            margin-bottom: 3px;
                          "
                          small
                          >mdi-delete</v-icon
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <div
                    v-if="loading"
                    style="
                      position: absolute;
                      top: 0;
                      left: -1rem;
                      height: 100%;
                      width: 100%;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <v-progress-circular
                      v-if="loading"
                      indeterminate
                      class="loading-circle"
                      color="green"
                    ></v-progress-circular>
                  </div>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-row style="margin-top: 5rem">
                  <v-col cols="12" xs="12" sm="12" md="6" lg="7">
                    <p
                      class="text-left font-style mb-1"
                      :style="fileError ? { color: 'red' } : {}"
                    >
                      {{ $t("Image Cover") }}
                    </p>
                    <div>
                      <v-card
                        width="290"
                        height="215"
                        style="
                          border: 4px dashed #c0c0c0;
                          box-shadow: unset;
                          background: #f8f8f8 0% 0% no-repeat padding-box;
                        "
                        @click="$refs.file.click()"
                        @dragover.prevent
                        @dragenter.prevent
                        @drop.prevent="dropImg($event)"
                      >
                        <v-img
                          :lazy-src="editSelectedArticle.orgImage"
                          :src="editSelectedArticle.orgImage"
                          @click="$refs.file.click()"
                          @mouseover="
                            editSelectedArticle.orgImage
                              ? (showEditImgTool = true)
                              : (showEditImgTool = false)
                          "
                          @mouseleave="
                            editSelectedArticle.orgImage
                              ? (showEditImgTool = false)
                              : (showEditImgTool = false)
                          "
                          :style="
                            fileError ? { border: '1px solid #FA5252' } : {}
                          "
                          width="240"
                          height="167"
                          style="border-radius: 8px !important"
                          class="mx-auto center mt-4"
                          v-if="editSelectedArticle.orgImage != null"
                        >
                          <v-card-title
                            v-if="showEditImgTool"
                            style="left: 15%; top: 15%; position: absolute"
                          >
                            <v-list
                              class="d-flex flex-column align-start"
                              style="padding: 0; border-radius: 8px"
                            >
                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="#424242"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="null, (previewimg = true)"
                                >
                                  <v-icon left>mdi-eye-outline</v-icon>
                                  {{ $t("preview") }}
                                </v-btn>
                              </v-list-item>

                              <v-list-item class="list-item-padding">
                                <v-btn
                                  color="error"
                                  style="font-size: 14px; width: 100px"
                                  text
                                  class="text-capitalize"
                                  @click.stop="
                                    editSelectedArticle.orgImage = null
                                  "
                                >
                                  <v-icon left>mdi-delete-outline</v-icon>
                                  {{ $t("delete") }}
                                </v-btn>
                              </v-list-item>
                            </v-list>
                          </v-card-title>
                        </v-img>

                        <v-img
                          v-if="editSelectedArticle.orgImage == null"
                          src="@/assets/image_loader.png"
                          width="64"
                          height="52"
                          class="mx-auto center"
                          style="margin-top: 2.5rem"
                          @click="$refs.file.click()"
                        ></v-img>
                        <input
                          type="file"
                          class="d-none"
                          ref="file"
                          accept="image/*"
                          @change="uploadImg($event, 1)"
                        />
                        <p
                          v-show="!editSelectedArticle.orgImage"
                          class="text-center mt-3"
                          style="
                            color: #424242;
                            font-size: 14px;
                            opacity: 0.6;
                            margin-bottom: 0;
                          "
                        >
                          {{ $t("DragDropfile") }}
                        </p>
                        <p
                          v-show="!editSelectedArticle.orgImage"
                          class="text-center"
                          style="color: #424242; font-size: 14px; opacity: 0.6"
                        >
                          {{ $t("OrBrowseFile") }} JPG, PNG"
                        </p>
                      </v-card>
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="5">
                    <v-row>
                      <v-btn
                        color="#A6CC39"
                        style="
                          font-size: 16px;
                          margin-top: 3rem;
                          float: left;
                          border-radius: 8px;
                          height: 40px;
                          width: 165px;
                        "
                        class="text-capitalize white--text btn_hover_effect"
                        @click="$refs.file.click()"
                      >
                        <v-icon class="mr-1">mdi-plus-box-outline</v-icon>
                        {{ $t("Add Cover") }}
                      </v-btn>
                      <p class="mt-3" style="color: #777777; font-size: 12px">
                        {{ $t("RecommendFormatPNG") }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col style="padding-bottom: 0">
                <label for="wordContent" class="font-style">
                  {{ $t("wordcontent") }}
                  <span style="color: red">*</span>
                </label>
                <v-textarea
                  :rules="wordContentRules"
                  v-model="editSelectedArticle.wordContent"
                  outlined
                  no-resize
                  id="wordContent"
                  class="rounded-lg"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mt-3 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize btnfont-style"
            text
            @click="cancelEditArticle()"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            width="100"
            @click="UpdateCourseArticle()"
            >{{ $t("update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- cropimage -->
    <v-dialog v-model="dialogCrop" width="1000px" height="800px">
      <v-card class="rounded-lg">
        <v-card-title
          class="white--text pb-4"
          style="background-color: #a6cc39; font-size: 30px"
          >Crop Image</v-card-title
        >
        <v-card-text
          class="pt-12"
          style="height: 550px; padding-left: 4rem; padding-right: 4rem"
        >
          <vue-cropper
            ref="cropper"
            :img="option.img"
            :output-size="option.size"
            :output-type="option.outputType"
            :info="true"
            :full="option.full"
            :can-move="option.canMove"
            :can-move-box="option.canMoveBox"
            :fixed-box="option.fixedBox"
            :original="option.original"
            :auto-crop="option.autoCrop"
            :auto-crop-width="option.autoCropWidth"
            :auto-crop-height="option.autoCropHeight"
            :center-box="option.centerBox"
            :high="option.high"
          ></vue-cropper>
        </v-card-text>
        <v-card-actions class="mt-3 pb-4" style="margin-right: 50px">
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize font-style"
            style="
              border: 1px solid #c0c0c0;
              border-radius: 8px;
              color: #424242;
            "
            text
            @click="dialogCrop = false"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            class="text-capitalize btnfont-style btn_hover_effect"
            style="color: #ffff; border: 1px solid #a6cc39; border-radius: 8px"
            color="#A6CC39"
            @click="CropImage()"
            width="100"
            >{{ $t("submit") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- preview image -->
    <v-dialog
      v-model="previewimg"
      persistent
      width="800px"
      height="800px"
      overflow="hidden"
      content-class="elevation-0 preview_class"
    >
      <v-card overflow="hidden" color="transparent">
        <v-card-title style="padding-right: 0 !important">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="previewimg = false"
            color="#FFFFFF"
            style="border-radius: 8px !important; border: 1px solid #ffffff30"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          width="800px"
          height="500px"
          style="border-radius: 8px; margin: auto"
          :src="editSelectedArticle.orgImage"
        ></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
import { VueCropper } from "vue-cropper";
import { mapGetters } from "vuex";

export default {
  components: {
    VueCropper,
  },
  props: {
    articleItem: {
      type: Object,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      updatePDF: false,
      editArticle: [],
      valid: true,
      fileError: false,
      title: null,
      //......................
      previewimg: false,
      dialogCrop: false,
      showEditImgTool: false,
      notLink: false,
      loading: false,
      //   article: {
      //     title: "",
      //     wordContent: "",
      //     orgImage: "",
      //     link: "",
      //     pdf_Url: "",
      //     pdfName: "",
      //     createBy: null,
      //     PDFButtonDisable: false, //not for save
      //     file: null, //not for save
      //   },
      //crop image
      option: {
        img: "",
        size: 1,
        full: false,
        outputType: "png",
        canMove: true,
        fixedBox: true,
        original: false,
        canMoveBox: false,
        autoCrop: true,
        autoCropWidth: 800,
        autoCropHeight: 450,
        centerBox: false,
        high: true,
      },
    };
  },

  watch: {
    "editSelectedArticle.file": function () {
      this.fileError = false;
    },
  },

  computed: {
    ...mapGetters(["editSelectedArticle"]),

    showArticleDialog: {
      get() {
        return this.show;
      },
      set() {
        this.$emit("close");
      },
    },
    titleRules() {
      return [(v) => !!v || "Title is required"];
    },
    wordContentRules() {
      return [(v) => !!v || "Word Content is required"];
    },
    isLink() {
      const urlRegex = /^(https?:\/\/(www\.)|(www\.))|(ftp|http(s)?:\/\/).+$/;
      return urlRegex.test(this.editSelectedArticle.link);
    },
  },

  methods: {
    cancelEditArticle() {
      let self = this;
      self.$refs.file.value = null;
      self.updatePDF = false;
      self.$store.commit("removeEditSelectedArticle");
      self.$emit("close");
      self.$refs.form.resetValidation();
    },

    async UpdateCourseArticle() {
      let self = this;
      let base64Res = {};
      if (self.$refs.form.validate() && self.notLink === false) {
        if (
          self.editSelectedArticle.file !== "" &&
          self.editSelectedArticle.file !== null
        ) {
          self.loading = true;
          base64Res = await self.$axios.post(
            `${self.web_url}File/UploadFileStringBase`,
            {
              base64: self.editSelectedArticle.file,
              fileName: self.editSelectedArticle.fileName,
            }
          );
        }

        if (self.updatePDF) {
          let formData = new FormData();
          formData.append(
            "file",
            self.editSelectedArticle.pdf_Url.target.files[0]
          );
          const res = await self.$axios.post(
            `${self.web_url}File/UploadPDFFile`,
            formData
          );
          self.editSelectedArticle.pdf_Url = res.data.data;
        }


        const userdata = JSON.parse(
          localStorage.getItem("vivek_authenticated_user_data")
        );
        const memberid = userdata.memberId;
        const data = {
          assignmentArticleID: self.editSelectedArticle.assignmentArticleID,
          title: self.editSelectedArticle.title,
          wordContent: self.editSelectedArticle.wordContent,
          orgImage: base64Res.data
            ? base64Res.data
            : self.editSelectedArticle.orgImage,
          link: self.editSelectedArticle.link,
          pdfUrl: self.editSelectedArticle.pdf_Url,
          pdfName: self.editSelectedArticle.pdfName,
          updateBy: memberid,
        };
        await self.$axios
          .post(
            `${self.web_urlV6}AssignmentArticle/UpdateAssignmentArticle`,
            data
          )
          .then(function (response) {
            if (response.data.status == 0) {
              let result = response.data.data ? response.data.data : [];
              self.$store.commit("saveSelectedArticle", result);
              self.$refs.file.value = null;
              self.updatePDF = false;
              self.$emit("close");
              self.$refs.form.resetValidation();
              self.fileError = false;
            }
          })
          .catch(function (err) {
            throw err;
          });
      } else {
        alert("Incomplete information Please enter again!");
      }
      self.loading = false;
    },

    checkLinkType() {
      if (this.isLink || this.editSelectedArticle.link === "") {
        this.notLink = false;
      } else {
        this.notLink = true;
      }
    },
    getFile(e) {
      this.editSelectedArticle.fileUrl = e;
      this.editSelectedArticle.file = e;
      this.editSelectedArticle.orgImage = e;
      this.showEditImgTool = false;
    },
    CropImage() {
      this.$refs.cropper.getCropData((data) => {
        this.getFile(data);
        this.dialogCrop = false;
      });
    },
    DeletePDFfile() {
      let that = this;
      that.editSelectedArticle.pdfName = "";
      that.editSelectedArticle.pdf_Url = "";
      that.editSelectedArticle.PDFButtonDisable = false;
    },
    async getpdfFile(e) {
      let that = this;
      let file = e.target.files[0];
      if (!/\.pdf$/.test(e.target.value)) {
        alert("Choose PDF files only, please re-select!");
        return false;
      } else {
        that.editSelectedArticle.pdfName = file.name;
        that.editSelectedArticle.pdf_Url = e;
        that.editSelectedArticle.PDFButtonDisable = true;
        that.updatePDF = true;
      }
    },
    dropImg(e) {
      let file = e.dataTransfer.files[0];
      if (!/(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(file.name)) {
        alert("Please choose image!");
        return false;
      }
      this.editSelectedArticle.fileName = file.name;
      let reader = new FileReader();
      reader.onload = (f) => {
        // f.target.result contains the base64 encoding of the image
        // let src = f.target.result;
        let src;
        if (typeof f.target.result === "object") {
          src = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          src = f.target.result;
        }
        this.option.img = src;
        this.dialogCrop = true;
      };
      reader.readAsArrayBuffer(file);
    },
    uploadImg(e, num) {
      let self = this;
      let file = e.target.files[0];
      if (!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
        alert("Please choose image!");
        return false;
      }
      this.editSelectedArticle.fileName = file.name;
      let reader = new FileReader();
      reader.onload = function (f) {
        let data;
        if (typeof f.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([f.target.result]));
        } else {
          data = f.target.result;
        }
        if (num === 1) {
          self.option.img = data;
          self.dialogCrop = true;
        }
      };
      reader.readAsArrayBuffer(file);
    },
  },
};
</script>
  
  <style scoped>
::v-deep .preview_class.v-dialog {
  /* border-radius: 16px; */
  overflow-y: hidden !important;
}
.btn_hover_effect,
.reset-btn {
  color: #fff;
  width: 80%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .primary--text {
  color: #a6cc39 !important;
}
</style>